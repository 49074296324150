import React from 'react'
import { motion } from 'framer-motion'

const Logo = ({
  color = 'currentColor',
  width = 220,
  height = 28,
  ...restProps
}) => {
  return (
    <motion.svg width={width} height={height} {...restProps} version="1.2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 220 28">
      <polygon fill={color} points="202.951,1.583 217.308,23.277 217.377,23.277 217.377,1.583 219.724,1.583 219.724,26.416
        216.997,26.416 202.709,4.756 202.64,4.756 202.64,26.416 200.293,26.416 200.293,1.583 "/>
      <path fill={color} d="M180.587,0.928c1.31,0,2.537,0.161,3.676,0.483c1.138,0.322,2.161,0.81,3.072,1.466
        c0.908,0.655,1.674,1.472,2.294,2.449c0.621,0.977,1.07,2.134,1.346,3.466h-2.417c-0.206-0.92-0.558-1.748-1.053-2.483
        c-0.493-0.736-1.086-1.35-1.777-1.846c-0.689-0.493-1.461-0.873-2.311-1.137c-0.852-0.265-1.739-0.397-2.657-0.397
        c-1.635,0-3.039,0.288-4.211,0.862c-1.174,0.575-2.139,1.386-2.9,2.43c-0.759,1.049-1.317,2.301-1.674,3.761
        c-0.356,1.461-0.534,3.064-0.534,4.811c0,1.38,0.2,2.691,0.603,3.931c0.403,1.243,0.988,2.335,1.759,3.279
        c0.771,0.942,1.714,1.69,2.83,2.241c1.117,0.552,2.387,0.828,3.814,0.828c0.668,0,1.357-0.074,2.07-0.224
        c0.714-0.15,1.403-0.397,2.071-0.742c0.667-0.345,1.288-0.793,1.863-1.346c0.575-0.552,1.071-1.23,1.484-2.034
        c0.391-0.782,0.663-1.598,0.811-2.449c0.15-0.85,0.237-1.747,0.26-2.69h-8.387v-2.001h10.629v12.83h-2.001v-4.243
        c-0.439,0.737-0.937,1.41-1.503,2.019c-0.563,0.609-1.212,1.127-1.948,1.552c-0.738,0.426-1.588,0.754-2.555,0.982
        c-0.966,0.231-2.059,0.346-3.277,0.346c-1.566,0-3.014-0.276-4.35-0.828c-1.334-0.552-2.486-1.374-3.452-2.466
        c-0.966-1.092-1.72-2.467-2.259-4.121c-0.541-1.656-0.812-3.587-0.812-5.794c0-1.817,0.232-3.512,0.692-5.088
        c0.459-1.575,1.168-2.943,2.122-4.104c0.954-1.161,2.152-2.076,3.59-2.743C176.933,1.261,178.632,0.928,180.587,0.928L180.587,0.928
        z"/>
      <rect x="157.843" y="1.583" fill={color} width="2.347" height="24.834"/>
      <path fill={color} d="M140.173,0.928c1.402,0,2.628,0.195,3.676,0.586c1.046,0.392,1.915,0.927,2.606,1.605
        c0.689,0.677,1.206,1.465,1.552,2.361c0.346,0.898,0.518,1.852,0.518,2.863h-2.243c0-0.943-0.166-1.759-0.499-2.448
        c-0.334-0.691-0.789-1.253-1.364-1.691c-0.576-0.436-1.23-0.759-1.968-0.966c-0.737-0.207-1.496-0.31-2.277-0.31
        c-1.197,0-2.203,0.166-3.02,0.499c-0.817,0.334-1.468,0.765-1.951,1.294s-0.817,1.117-1.001,1.759
        c-0.184,0.643-0.219,1.287-0.104,1.931c0.139,0.805,0.461,1.449,0.967,1.932c0.508,0.484,1.129,0.879,1.863,1.19
        c0.737,0.31,1.548,0.563,2.434,0.759c0.885,0.195,1.788,0.403,2.708,0.621c0.921,0.219,1.812,0.477,2.675,0.776
        c0.863,0.3,1.628,0.701,2.295,1.208c0.667,0.505,1.202,1.155,1.606,1.948c0.401,0.792,0.603,1.788,0.603,2.983
        c0,2.301-0.793,4.083-2.381,5.346c-1.588,1.265-3.819,1.898-6.695,1.898c-1.29,0-2.486-0.168-3.59-0.501
        c-1.103-0.332-2.059-0.839-2.865-1.517c-0.805-0.678-1.432-1.519-1.881-2.519c-0.448-1-0.672-2.177-0.672-3.535h2.277
        c0.092,1.103,0.339,2.041,0.743,2.811c0.401,0.77,0.919,1.397,1.552,1.879c0.632,0.483,1.364,0.835,2.192,1.052
        c0.828,0.22,1.714,0.328,2.659,0.328c1.104,0,2.057-0.138,2.863-0.414c0.806-0.275,1.463-0.65,1.968-1.121
        c0.507-0.471,0.881-1.016,1.123-1.637c0.241-0.622,0.361-1.277,0.361-1.966c0-0.967-0.219-1.749-0.654-2.346
        c-0.439-0.599-1.008-1.088-1.708-1.467c-0.703-0.38-1.508-0.684-2.417-0.914c-0.91-0.229-1.841-0.454-2.797-0.673
        c-0.954-0.217-1.886-0.477-2.794-0.776c-0.909-0.298-1.715-0.701-2.416-1.206c-0.701-0.506-1.272-1.157-1.708-1.95
        c-0.437-0.794-0.657-1.798-0.657-3.018c0-0.851,0.179-1.679,0.534-2.483c0.358-0.805,0.888-1.517,1.588-2.138
        c0.702-0.621,1.582-1.116,2.641-1.483C137.573,1.112,138.792,0.928,140.173,0.928L140.173,0.928z"/>
      <polygon fill={color} points="124.469,1.583 124.469,3.583 110.284,3.583 110.284,12.551 123.088,12.551 123.088,14.552
        110.284,14.552 110.284,24.416 124.814,24.416 124.814,26.416 107.937,26.416 107.937,1.583 "/>
      <path fill={color} d="M99.673,11.259c-0.128-0.979-0.345-1.944-0.657-2.899c-0.309-0.954-0.73-1.862-1.259-2.725
        c-0.53-0.862-1.208-1.592-2.037-2.19c-0.575-0.413-1.168-0.747-1.777-0.999c-0.61-0.254-1.237-0.442-1.881-0.569
        c-0.644-0.128-1.3-0.208-1.967-0.242c-0.668-0.035-1.346-0.051-2.037-0.051h-8.49v24.832h8.49c0.83,0,1.646-0.04,2.452-0.12
        c0.804-0.081,1.581-0.229,2.328-0.449c0.748-0.217,1.461-0.522,2.14-0.914c0.679-0.391,1.305-0.908,1.881-1.551
        c0.575-0.621,1.053-1.305,1.432-2.053c0.38-0.746,0.685-1.53,0.914-2.346c0.229-0.817,0.397-1.649,0.501-2.499
        c0.105-0.852,0.157-1.692,0.157-2.519S99.799,12.234,99.673,11.259z M97.327,16.621c-0.128,0.92-0.346,1.799-0.657,2.639
        c-0.31,0.838-0.73,1.615-1.26,2.327c-0.529,0.712-1.206,1.276-2.036,1.69c-0.966,0.506-1.916,0.823-2.848,0.948
        c-0.932,0.127-1.927,0.19-2.984,0.19h-5.627V3.583h5.627c1.034,0,2.058,0.059,3.072,0.172c1.011,0.117,1.886,0.369,2.622,0.759
        c0.875,0.461,1.589,1.041,2.139,1.742c0.554,0.701,0.99,1.471,1.312,2.31s0.541,1.725,0.656,2.656
        c0.114,0.931,0.174,1.846,0.174,2.742C97.517,14.816,97.452,15.702,97.327,16.621z"/>
      <polygon fill={color} points="60.295,1.785 60.295,22.175 72.711,22.175 72.711,26.644 55.098,26.644 55.098,1.785 "/>
      <path fill={color} d="M45.281,3.708c-1.463-1.282-3.426-1.923-5.888-1.923H28.716v24.859h5.162v-8.939h5.178
        c2.702,0,4.781-0.606,6.235-1.821c1.457-1.214,2.185-3.288,2.185-6.223C47.476,6.974,46.744,4.989,45.281,3.708z M41.327,12.528
        c-0.658,0.596-1.579,0.894-2.766,0.894h-4.683v-7.32h4.683c1.186,0,2.108,0.275,2.766,0.826c0.657,0.552,0.987,1.472,0.987,2.766
        S41.984,11.932,41.327,12.528z"/>
      <polygon fill={color} points="5.387,1.785 5.387,12.027 14.995,1.785 21.709,1.785 11.505,12.008 22.233,26.644
        15.552,26.644 7.89,15.793 5.387,18.329 5.387,26.644 0.276,26.644 0.276,1.785 "/>
    </motion.svg>
  )
}

export default Logo
