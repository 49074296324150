import React, { useCallback, useState } from 'react'
import { Link } from 'gatsby'
import { AnimatePresence, motion } from 'framer-motion'

import locales from '../../../config/i18n'
import { classes } from '../../utils/helpers'
import useLockBodyScroll from '../../hooks/useLockBodyScroll'
import useMenu from '../useMenu'
import useTranslations, { useLocale } from '../useTranslations'
import LocalizedLink from '../LocalizedLink'
import { Hamburger } from '../atoms/Hamburger'

import styles from './Navigation.module.sass'

import logo from '../../images/kpl-logo.svg'
import Logo from '../Logo'

const AnimatedItem = ({ children, index }) =>
  <motion.div
    initial={{ opacity: 0, x: 20 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: -20 }}
    transition={{ delay: index * 0.1 }}
  >
    {children}
  </motion.div>

const LockBodyScroll = () => {
  useLockBodyScroll()
  useLockBodyScroll(document.querySelector('#___gatsby'))
  return null
}

export function Navigation() {
  const menuItems = useMenu()
  const { button } = useTranslations()

  return (
    <div className={styles.NavigationContainer}>
      <nav className={styles.Navigation}>
        {menuItems.map(menu => (
          <LocalizedLink
            key={menu.link}
            to={menu.link}
            className={styles.NavigationLink}
            aria-label={menu.name}
            activeClassName="active"
          >
            {menu.name}
          </LocalizedLink>
        ))}
      </nav>
    </div>
  )
}

export function NavigationMobile() {
  const menuItems = useMenu()
  const { button } = useTranslations()
  const { locale } = useLocale()

  const [active, setActive] = useState(false)
  const handleToggle = useCallback((e) => {
    setActive(prev => !prev)
  }, [setActive])

  return (
    <>
      <Logo
        className={styles.LogoMobile}
        initial={{ color: '#1a1a1d' }}
        animate={{ color: active ? '#1a1a1d' : '#fff' }}
      />
      <Hamburger active={active} onClick={handleToggle} />
      <AnimatePresence>
        {active &&
          <motion.nav
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ staggerChildren: 200 }}
            className={styles.NavigationMobile}
            onClick={handleToggle}
          >
            <LockBodyScroll />
            {menuItems.map((menu, i )=> (
              <AnimatedItem
                key={menu.link}
                index={i}
              >
                <LocalizedLink
                  to={menu.link}
                  className={styles.NavigationLinkMobile}
                  aria-label={menu.name}
                  activeClassName="active"
                >
                  {menu.name}
                </LocalizedLink>
              </AnimatedItem>
            ))}
            <AnimatedItem index={menuItems.length}>
              <Link
                className={classes(styles.NavigationLinkMobile, locale === locales.cs.siteLanguage && 'active')}
                to="/"
                hrefLang="cs"
              >
                CZ
              </Link>
            </AnimatedItem>
            <AnimatedItem index={menuItems.length + 1}>
              <Link
                className={classes(styles.NavigationLinkMobile, locale === locales.cs.siteLanguage && 'active')}
                to="/en"
                hrefLang="en"
              >
                EN
              </Link>
            </AnimatedItem>
          </motion.nav>
        }
      </AnimatePresence>
    </>
  )
}
