import React from 'react'

import Logo from '../Logo'

import styles from './Footer.module.sass'

export const MailAndPhone = () =>
  <div>
    <p>kpl@kpl-design.cz</p>
    <p>+420 286 582 314</p>
  </div>

export const Address = () =>
  <div>
    <p>Nymburská 50</p>
    <p>Praha - Kbely</p>
  </div>

export const IDs = () =>
  <div>
    <p>ICO: 26738813</p>
    <p>DIC: CZ26738813</p>
  </div>

const Footer = () =>
  <footer className={styles.Wrapper}>
    <div className={styles.Container}>
      <Logo/>
      <MailAndPhone />
      <Address />
      <IDs />
    </div>
  </footer>

export default Footer
