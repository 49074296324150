import { ReactElement } from 'react'

import { useIsMobile } from '../../hooks/useMedia'

interface MediaQueriesProps {
  children: ReactElement
}

export function MobileOnly ({ children }: MediaQueriesProps) {
  return useIsMobile() ? children : null
}

export function DesktopOnly ({ children }: MediaQueriesProps) {
  return !useIsMobile() ? children : null
}
