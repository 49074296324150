import React, { useState } from 'react'
import useTranslations from '../useTranslations'
import { Navigation, NavigationMobile, NavigationToggle } from '../Navigation'
import Languages from '../Languages'
import Logo from '../Logo'
import LocalizedLink from '../LocalizedLink'

import styles from './Header.module.sass'
import { DesktopOnly, MobileOnly } from '../atoms/MediaQueries'

const Header = () => {
  const { home } = useTranslations()

  return (
    <header className={styles.Wrapper}>
      <div className={styles.Container}>

      <DesktopOnly>
        <LocalizedLink
          className={styles.LogoLink}
          to="/"
          title={home}
          aria-label={home}
        >
          <Logo />
        </LocalizedLink>
          <Navigation />
          <Languages />
        </DesktopOnly>

        <MobileOnly>
          <NavigationMobile />
        </MobileOnly>
      </div>
    </header>
  )
}

export default Header
