module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1040,"linkImagesToOriginal":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"KPL Design website","short_name":"KPL Design","start_url":"/","background_color":"#1a1a1d","theme_color":"#1a1a1d","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"cb95f10cbe3ce6e951a8d6ef17fcf978"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
