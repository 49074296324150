import { useCallback, useEffect, useMemo, useState } from 'react'

import { MediaQueries } from '../utils/constants'
import useWindow from './useWindow'

export function useMedia<T> (queries: string[], values: T[], defaultValue: T) {
  const window = useWindow()
  // Array containing a media query list for each query
  const mediaQueryLists = useMemo(() => window && queries.map(q => window.matchMedia(q)), [])
  const getValue = useCallback(() => {
    if (!window) return defaultValue
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex(mql => mql.matches)
    return values[index] ?? defaultValue
  }, [mediaQueryLists, defaultValue])

  const [value, setValue] = useState(getValue)

  useEffect(() => {
    if (!window) return

    const handler = () => setValue(getValue())
    mediaQueryLists.forEach(mql => mql.addListener(handler))
    return () => mediaQueryLists.forEach(mql => mql.removeListener(handler))
  }, [getValue, mediaQueryLists])

  return value
}

export function useIsMobile (): boolean {
  const isMobile = useMedia<boolean>([MediaQueries.small], [true], false)
  return isMobile
}

export function useIsMobileOrTablet (): boolean {
  const isMobile = useMedia<boolean>([MediaQueries.small, MediaQueries.medium], [true, true], false)
  return isMobile
}
