import React, { useContext } from 'react'
import { Link } from 'gatsby'

import locales from '../../../config/i18n'

import styles from './Languages.module.sass'
import { useLocale } from '../useTranslations'

const hidden = { display: 'none' }

const Languages = () => {
  const locale = useLocale()

  return (
    <div className={styles.LanguageWrapper}>
      <Link
        className={styles.LanguageLink} to="/" hrefLang="cs"
        style={locale === locales.cs.siteLanguage ? hidden : null}
      >
        CZ
      </Link>
      <Link
        className={styles.LanguageLink} to="/en" hrefLang="en"
        style={locale === locales.en.siteLanguage ? hidden : null}
      >
        EN
      </Link>
    </div>
  )
}

export default Languages
