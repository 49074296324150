import React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../Header'
import Footer from '../Footer'

import '../../styles/global.sass'

import styles from './styles.module.sass'

const LocaleContext = React.createContext()

// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!
const Layout = ({ children, pageContext: { locale } }) => (
  <LocaleContext.Provider value={{ locale }}>
    <Helmet htmlAttributes={{ lang: locale }}>
      <link rel="preconnect" href="https://fonts.gstatic.com"/>
      <link href="https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,500;0,700;1,500&display=swap" rel="stylesheet"/>
    </Helmet>
    <div className={styles.Wrapper}>
      <Header />
      <main role="main" className={styles.SiteContent}>
        {children}
      </main>
      <Footer />
    </div>
  </LocaleContext.Provider>
)

export { Layout, LocaleContext }
