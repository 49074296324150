export const ANIMATIONS = {
  'initial': 'ENTERING',
  'animate': 'ENTERED',
  'exit': 'EXITING'
}

export const Breakpoints = {
  small: 450,
  medium: 768,
  large: 1170,
  huge: 1440
}

export const MediaQueries = {
  tiny: `(max-width: ${Breakpoints.tiny}px)`,
  small: `(max-width: ${Breakpoints.small - 1}px) and (orientation: portrait)`,
  smallLandscape: `(max-height: ${Breakpoints.small - 1}px) and (orientation: landscape)`,
  medium: `(min-width: ${Breakpoints.small}px) and (max-width: ${Breakpoints.medium - 1}px)`,
  large: `(min-width: ${Breakpoints.medium}px) and (max-width: ${Breakpoints.large - 1}px)`,
  huge: `(min-width: ${Breakpoints.large}px)`,
  mediumPlus: `(min-width: ${Breakpoints.small}px)`,
  largePlus: `(min-width: ${Breakpoints.medium}px)`
}
